<template>
  <div class="card">
    <template v-if="!getCRS">
      <div v-for="(item, i) in controlMenuList">
        <div class="col1_inner" :class="{ hid: !item.show, mobile: isMobile }">
          <div class="col1_head heading-style" @click="showList(i)">
            <div>
              <div class="vector">
                <i class="fas fa-angle-down"></i>
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="col1_menu">
            <transition name="SidebarMenuList">
              <div
                v-show="item.show"
                @click="checkMobile"
                class="col1_list"
                ref="MenuList"
              >
                <router-link
                  class="col1_list_item"
                  v-for="element in item.elements"
                  @click.native="reloadCurr(element.path)"
                  :class="element.selected ? 'not-exact-router' : ''"
                  :to="element.path"
                  :id="element.path"
                  :key="element.name"
                >
                  <div class="icon">
                    <img
                      v-if="!Array.isArray(element.icon)"
                      v-bind:src="element.icon"
                    />
                    <i v-else :class="element.icon[0]"></i>
                  </div>
                  <div class="item_title" style="flex: 1">
                    {{ element.name }}
                  </div>
                  <div
                    v-if="element.count"
                    v-tooltip="'Numărul de proceduri active'"
                  >
                    {{ element.count }}
                  </div>
                </router-link>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="col1_inner"
      :class="{ hid: !getCRS.opened, mobile: isMobile }"
    >
      <div
        class="col1_head heading-style"
        @click="getCRS.opened = !getCRS.opened"
      >
        <div v-tooltip="'Secțiuni referat necesitate'">
          <div class="vector">
            <i class="fas fa-angle-down"></i>
          </div>
          <span>Secțiuni referat necesitate</span>
        </div>
      </div>
      <div @click="$emit('closeMobileSidebar')" class="col1_menu">
        <transition name="SidebarMenuList">
          <div
            id="col1_references"
            class="col1_list"
            v-show="getCRS.opened"
            ref="MenuListAdministration"
            style="overflow: auto; max-height: 60rem"
          >
            <div
              v-for="(e, i) in getCRS.sections"
              :key="i"
              @click="getCRS.selectSection(e.id)"
              :id="`col1_reference_section_${e.id}`"
              class="col1_list_item between hideShowOnHover"
              :class="{
                'router-link-exact-active': e.isCurrent && e.active !== false,
              }"
              v-tooltip="e.full"
            >
              <span class="ellipsis table-vertival-center">
                <span
                  v-if="e.dynamical || e.active != undefined"
                  style="text-align: center; width: 16px; margin-right: 0.5rem"
                  class="centerImg"
                >
                  <img
                    v-if="e.dynamical"
                    style="width: 16px"
                    src="@/assets/media/plus-square.svg"
                    alt=""
                  />
                  <input
                    v-else-if="e.active != undefined"
                    v-model="getCRS.actives[`S${e.id}`]"
                    type="checkbox"
                    style="margin: 0 0.5rem 0 0; width: 2rem"
                  />
                </span>
                <span style="line-height: 13px">{{
                  textFromHtml(e.short)
                }}</span>
              </span>
              <span
                @click.stop=""
                style="
                  cursor: default;
                  display: inline-flex;
                  align-items: center;
                  margin-left: 0.5rem;
                "
              >
                <span
                  v-if="e.dynamical"
                  @click="getCRS.editDynamicSection(e)"
                  v-tooltip="'Editează secțiunea dinamică'"
                  class="_Hid centerImg"
                  style="width: 20px; text-align: left"
                >
                  <img
                    src="@/assets/media/edit.svg"
                    style="width: 16px"
                    alt=""
                  />
                </span>
                <span
                  v-if="e.active !== false"
                  style="text-align: center; width: 16px; margin-right: 0.5rem"
                  class="centerImg"
                  :class="{ _HidHover: e.dynamical }"
                >
                  <i
                    v-show="e.valide === false"
                    class="far fa-clock"
                    style="
                      margin-right: 0.5rem;
                      color: #f2da07;
                      width: 2rem;
                      text-align: center;
                    "
                    v-tooltip="'În proces de completare'"
                  ></i>
                  <img
                    v-show="e.valide !== false"
                    style="width: 16px"
                    src="@/assets/media/circle_chk.svg"
                    alt=""
                    v-tooltip="'Valid'"
                  />
                </span>
              </span>
            </div>
          </div>
        </transition>
        <div
          v-show="getCRS.opened"
          @click="getCRS.createSection"
          class="col1_list_item between"
          style="background-color: #c8ddea; border-top: 2px solid #8dc5e8"
        >
          <span class="ellipsis table-vertival-center">
            <span style="line-height: 13px; font-weight: 500"
              >Adaugă secțiune</span
            >
          </span>
          <span
            class="centerImg"
            style="
              display: inline-flex;
              align-items: flex-start;
              margin-left: 0.5rem;
              width: 20px;
            "
          >
            <img
              style="width: 16px"
              src="@/assets/media/plus-square.svg"
              alt=""
            />
          </span>
        </div>
      </div>
    </div>
    <div v-if="!hideMinimizer" class="sidebarMinimize">
      <div v-if="!isMinimized" @click="isMinimized = true" class="sidebarBtn">
        <i class="fas fa-arrow-left"></i>
      </div>
      <div v-else @click="isMinimized = false" class="sidebarBtn">
        <i class="fas fa-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<style>
.SidebarMenuList-enter-active {
  transition: transform 0.2s;
}
.SidebarMenuList-leave-active {
  transition: none;
}
.SidebarMenuList-enter,
.SidebarMenuList-leave-to {
  transform: translateY(-100%);
}
</style>

<script>
import PermisionsMixin from "@/mixins/allPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import MainMixin from "@/mixins/mainMixin.js";
import { mapGetters, mapMutations } from "vuex";
// import I1 from '@/assets/media/cont_personal.svg'
// import I2 from '@/assets/media/acte_personale.svg'
// import I3 from '@/assets/media/certificate_digitale.svg'
// import I4 from '@/assets/media/notificari.svg'
import I5 from "@/assets/media/proiecte.svg";
// import I6 from '@/assets/media/propuneri.svg'
import I7 from "@/assets/media/produse.svg";
import I8 from "@/assets/media/unitati_masura.svg";
import I9 from "@/assets/media/needs.svg";
import I10 from "@/assets/media/institutions.svg";
import I11 from "@/assets/media/departments.svg";
import I12 from "@/assets/media/money.svg";
import I13 from "@/assets/media/reference.svg";
import I14 from "@/assets/media/procedure_type.svg";
// import I15 from '@/assets/media/needs_approved.png'
import I16 from "@/assets/media/bugetare.png";
import { PROCEDURE_TYPE_MENU } from "../api";
const navigationGrouping = {
  planificare: [
    {
      name: "Catalog",
      icon: I7,
      selected: true,
      path: "/catalog",
      key: "products",
    },
    {
      name: "Propuneri de nevoi",
      icon: I9,
      selected: false,
      path: "/nevoi",
      key: "needs",
    },
    {
      name: "Avizare & Aprobare",
      icon: [["fas fa-bezier-curve"]],
      selected: false,
      path: "/nevoi/avizare-aprobare",
      key: "needs",
    },
    {
      name: "Referate necesitate",
      icon: I13,
      selected: false,
      path: "/referate",
      key: "needReference",
    },
    {
      name: "PAAP",
      icon: [["fas fa-book"]],
      selected: false,
      path: "/paap",
      key: "paap",
    },
  ],

  atribuire: [],
  evaluare: [
    {
      name: "Evaluare proiecte",
      icon: [["far fa-clock"]],
      selected: false,
      path: "/in_curand/4",
    },
    {
      name: "Analiza de impact",
      icon: [["far fa-clock"]],
      selected: false,
      path: "/in_curand/5",
    },
  ],

  monitorizare: [
    {
      name: "Proiecte",
      icon: I5,
      selected: true,
      path: "/proiecte",
    },
    {
      name: "Rapoarte achiziții",
      icon: [["far fa-cart-plus"]],
      selected: false,
      path: "/raport-centralizare",
    },
    {
      name: "Rapoarte produse",
      icon: [["far fa-cart-plus"]],
      selected: false,
      path: "/raport-produse",
    },
    {
      name: "Rapoarte buget",
      icon: [["far fa-money-bill"]],
      selected: false,
      path: "/raport-buget",
    },
  ],
  administrare: [
    {
      name: "Catalog",
      icon: I7,
      selected: true,
      path: "/catalog",
      key: "products",
      subList: {
        admin_institutie: "instrumente",
        responsabil_achizitii: "instrumente",
        superadmin: "administrare",
      },
    },
    {
      name: "Clasificație bugetare",
      icon: I16,
      selected: false,
      path: "/clasificatie_bugetare",
      key: "bugetare",
      subList: {
        superadmin: "nomenclatoare",
      },
    },

    {
      name: "Categorii CPV",
      icon: ["fas fa-list-alt"],
      selected: false,
      path: "/categorii_cpv",
      key: "cpvCategories",
      subList: {
        superadmin: "nomenclatoare",
      },
    },

    {
      name: "Valute",
      icon: ["fas fa-coins"],
      selected: false,
      path: "/valute",
      key: "currency",
      subList: {
        superadmin: "nomenclatoare",
      },
    },

    {
      name: "Registrul pragurilor",
      icon: ["fas fa-book"],
      selected: false,
      path: "/registrul_pragurilor",
      key: "register",
      subList: {
        superadmin: "nomenclatoare",
      },
    },
    {
      name: "Unități de masură",
      icon: I8,
      selected: false,
      path: "/unitati_masura",
      key: "measurmentUnits",
      subList: {
        superadmin: "nomenclatoare",
      },
    },
    {
      name: "Surse de finanțare",
      icon: ["fas fa-donate"],
      selected: false,
      path: "/surse_finantare",
      key: "financing",
      subList: {
        superadmin: "nomenclatoare",
      },
    },
    {
      name: "Tipuri contracte",
      icon: ["fas fa-file-contract"],
      selected: false,
      path: "/tipuri_contracte",
      key: "contract",
      subList: {
        superadmin: "nomenclatoare",
      },
    },
    {
      name: "Surse buget",
      icon: I12,
      selected: false,
      path: "/surse_buget",
      key: "budgetSources",
      subList: {
        superadmin: "nomenclatoare",
      },
    },

    {
      name: "Tipuri de proceduri",
      icon: I14,
      selected: false,
      path: "/tip_proceduri",
      key: "procedureTypes",
      subList: {
        superadmin: "nomenclatoare",
      },
    },
    {
      name: "Instituție",
      icon: ["fas fa-building"],
      selected: true,
      path: "/institutie",
      key: "institution",
      subList: {
        admin_institutie: "Profil instituție",
        responsabil_achizitii: "Profil instituție",
      },
    },

    {
      name: "Obiective instituție",
      icon: ["fas fa-bullseye"],
      selected: true,
      path: "/obietive-institutie",
      key: "institutionObjectives",
      subList: {
        admin_institutie: "instrumente",
        responsabil_achizitii: "instrumente",
      },
    },

    {
      name: "Obiective anuale",
      icon: ["fas fa-bullseye-arrow"],
      selected: true,
      path: "/obietive-anuale",
      key: "annualInstitutionObjectives",
      subList: {
        admin_institutie: "instrumente",
        responsabil_achizitii: "instrumente",
      },
    },

    {
      name: "Structuri organizatorice",
      icon: I11,
      selected: false,
      path: "/departamente",
      key: "departments",
      subList: {
        admin_institutie: "Profil instituție",
        responsabil_achizitii: "Profil instituție",
        superadmin: "administrare",
      },
    },
    {
      name: "Institutii",
      icon: I10,
      selected: false,
      path: "/institutii",
      key: "institutions",
      subList: {
        superadmin: "administrare",
      },
    },
    {
      name: "Utilizatori",
      icon: ["fas fa-user-friends"],
      selected: false,
      path: "/utilizatori",
      key: "users",
      subList: {
        admin_institutie: "Profil instituție",
        responsabil_achizitii: "Profil instituție",
        superadmin: "administrare",
      },
    },
    {
      name: "Furnizori",
      icon: ["fas fa-industry"],
      selected: false,
      path: "/furnizori",
      key: "institutionProvider",
      subList: {
        admin_institutie: "instrumente",
        responsabil_achizitii: "instrumente",
        responsabil_buget: "instrumente",
        superadmin: "nomenclatoare",
      },
    },
    {
      name: "Tipuri de structuri organizatorice",
      icon: ["fas fa-briefcase"],
      selected: false,
      path: "/structuri-organizatorice",
      key: "",
      subList: {
        superadmin: "nomenclatoare",
      },
    },

    {
      name: "Tipuri de instituții",
      icon: ["fas fa-building"],
      selected: false,
      path: "/tipuri-de-institutii",
      key: "",
      subList: {
        superadmin: "nomenclatoare",
      },
    },

    {
      name: "Tipuri de ordonatori de credite",
      icon: ["fas fa-building"],
      selected: false,
      path: "/ordonatori-credite",
      key: "",
      subList: {
        superadmin: "nomenclatoare",
      },
    },
  ],
};

export default {
  mixins: [PermisionsMixin, updateRoleStatus, MainMixin],
  data() {
    return {
      reloadPageTimeout: window["apiTimeout"](1000),
      controlMenuList: [],
      MenuListIsActive: false,
      currRole: "",
      isMinimized: false,
      hideMinimizer: false,
      lastUrl: "",
    };
  },
  computed: {
    ...mapGetters(["userRole", "getCRS"]),
    isMobile() {
      return window.innerWidth < 769;
    },
  },

  watch: {
    getCRS(x) {
      if (x) {
        this.hideMinimizer = true;
        this.isMinimized = false;
      } else {
        this.hideMinimizer = false;
      }
    },
    isMinimized(val) {
      this.$emit("minimizeSidebar", val);

      localStorage[val ? "setItem" : "removeItem"]("minimizeSidebar", true);
    },

    /*   MenuListIsActive(a) {
      localStorage.setItem("MenuListIsActive", a);
      if (window.innerWidth > 768) {
        this.MenuListIsActive = true;
      } 
    },*/

    userRole(val) {
      this.currRole = val.role;
      this.initAllPermisions();
      this.updateRoleStatus();
      this.permisionManager(val);
    },
    $route() {
      this.permisionManager();
      //this.syncMenuLists();
    },
    deep: true,
  },
  methods: {
    ...mapMutations(["setProcedureTypes"]),
    showList(i) {
      this.controlMenuList.forEach((el, j) => {
        if (i != j) el.show = false;
      });

      this.controlMenuList[i].show = !this.controlMenuList[i].show;
    },
    reloadCurr(x) {
      if (this.lastUrl == x) {
        this.reloadPageTimeout(() => this.$emit("refresh"));
      } else {
        this.lastUrl = this.$route.path;
      }
    },
    checkMobile() {
      if (this.isMobile) {
        this.MenuListIsActive = true;
      }
    },
    permisionManager() {
      const route = this.$route;

      const navigation = route.params.navigation;

      const x = this.PERMISIONS;
      const z = [];

      if (!navigation) {
        const list = {
          name: "PROFIL",
          show: false,
          elements: [
            {
              name: "Detalii rol",
              icon: ["fas fa-user"],
              selected: true,
              path: "/profil",
            },
          ],
        };
        z.push(list);
      } else {
        const map = new Map();

        const navigationList = navigationGrouping[navigation];

        for (const l of navigationList) {
          let mapKey = navigation;

          const subList = l.subList;

          if (subList) {
            const userRole = this.getUserRole();

            if (!subList[userRole]) continue;

            mapKey = subList[userRole];
          }

          const path = "/" + navigation + l.path;

          if (!map.has(mapKey)) {
            map.set(mapKey, [{ ...l, path }]);
          } else {
            const elements = map.get(mapKey);

            elements.push({ ...l, path });

            map.set(mapKey, elements);
          }
        }

        const orderList = [
          navigation,
          "Profil instituție",
          "instrumente",
          "nomenclatoare",
        ];

        for (const o of orderList) {
          if (map.has(o)) {
            const name = o.toUpperCase();
            let show = false;
            const elements = map.get(o);

            if (elements.find((el) => el.path == route.path)) {
              show = true;
            }

            const list = {
              name,
              show,
              elements,
            };
            z.push(list);
          }
        }
      }

      this.controlMenuList = z;
    },
  },
  created() {
    this.$root.$on("select-item", (val) => {
      setTimeout(() => {
        if (!val) return;
        document.getElementById(val).classList.add("router-link-exact-active");
      }, 10);
    });
    this.lastUrl = this.$route.path;

    this.initAllPermisions();
    this.updateRoleStatus();
    this.currRole = this.getUserRole();
    if (
      localStorage.getItem("MenuListIsActive") == "false" ||
      window.innerWidth < 769
    )
      this.MenuListIsActive = false;
    else this.MenuListIsActive = true;

    if (localStorage.getItem("minimizeSidebar") && window.innerWidth > 768) {
      this.isMinimized = true;
      this.$emit("minimizeSidebar", true);
    }

    PROCEDURE_TYPE_MENU()
      .then((res) => {
        navigationGrouping.atribuire = res.data
          .map((el) => ({
            name: el.name,
            icon: I13,
            selected: false,
            path:
              "/procedura/" +
              el.name
                .replace(/ /g, "_")
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, ""),
            key: "assignments",
            count: el.count,
          }))
          .filter((el) => el.count > 0);

        const procedureTypes = {};

        res.data.forEach((el) => {
          procedureTypes[
            el.name
              .replace(/ /g, "_")
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ] = { name: el.name, id: el.id };
        });

        this.setProcedureTypes(procedureTypes);

        this.permisionManager();
      })
      .catch((err) => {
        this.permisionManager();
      });

    // this.$nextTick(this.syncMenuLists);
  },
};
</script>
