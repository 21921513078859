import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Home'),
  },
  {
    path: '/app-login/*',
    name: 'Auto Login',
    meta: { layout: 'authLayout' },
    component: () => import('@/views/AutoLogin'),
  },
  {
    path: '/:navigation/in_curand/:id',
    name: 'In Curand',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/InCurand'),
  },
  {
    path: '/test',
    name: 'Test',
    meta: { layout: 'emptyLayout' },
    component: () => import('@/views/Test'),
  },
  {
    path: '/:navigation/procedura/:procedureType',
    name: 'Atribuire',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Assignments'),
  },
  {
    path: '/:navigation/procedura/:procedureType/:centralization',
    name: 'Atribuire referate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/AssigmentsNeedReferences'),
  },
  {
    path: '/:navigation/procedura/:procedureType/:centralization/achizitii',
    name: 'Documente achiziții - Generate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Acquisitions.vue'),
  },

  {
    path: '/:navigation/procedura/:procedureType/:centralization/proces-verbal',
    name: 'Procese Verbale - Generate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/VerbalProcessView.vue'),
  },

  {
    path: '/:navigation/procedura/:procedureType/:centralization/anunt',
    name: 'Anunțuri - Generate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/AnnouncementView.vue'),
  },
  /* {
    path: '/:navigation/achizitii_directe',
    name: 'Achiziții directe',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/achizitii_directe/:id',
    name: 'Achiziții directe ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/achizitii_directe/:id/:version',
    name: 'Versiune referat necesitate ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/proceduri_simplificate',
    name: 'Proceduri simplificate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/proceduri_simplificate/:id',
    name: 'Proceduri simplificate ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/proceduri_simplificate/:id/:version',
    name: 'Versiune referat necesitate  ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/licitatii_publice',
    name: 'Licitații publice',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/licitatii_publice/:id',
    name: 'Licitații publice ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/licitatii_publice/:id/:version',
    name: 'Versiune referat necesitate   ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  }, */
  {
    path: '/profil',
    name: 'Detalii rol',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Profil'),
  },
  {
    path: '/notificari',
    name: 'Notificări',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Notificari'),
  },
  {
    path: '/:navigation/registrul_pragurilor',
    name: 'Registrul pragurilor',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Registru'),
  },
  {
    path: '/:navigation/valute',
    name: 'Valute',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Currency'),
  },
  {
    path: '/:navigation/utilizatori',
    name: 'Utilizatori',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Users'),
  },
  {
    path: '/user/role/confirm/*',
    name: 'Confirmare rol',
    meta: { layout: 'authLayout' },
    component: () => import('@/views/RoleConfirm'),
  },
  {
    path: '/:navigation/institutii',
    name: 'Institutii',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Institutions'),
  },
  {
    path: '/:navigation/institutie',
    name: 'Institutie',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Institution'),
  },
  {
    path: '/:navigation/departamente',
    name: 'Structuri organizatorice',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Departments'),
  },
  {
    path: '/cont_personal',
    name: 'Cont personal',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ContPersonal'),
  },
  {
    path: '/acte_personale',
    name: 'Acte Personale',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ActePersonale'),
  },
  {
    path: '/certificate_digitale',
    name: 'Certificate Digitale',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/CertificateDigitale'),
  },
  {
    path: '/:navigation/proiecte',
    name: 'Proiecte',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Proiecte'),
  },
  {
    path: '/:navigation/proiecte/:id',
    name: 'Cheltuieli',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ProjectsExpenses'),
  },
  {
    path: '/:navigation/paap',
    name: 'Plan anual de achiziții publice',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Paap'),
  },
  {
    path: '/:navigation/paap/:id/centralizare',
    name: 'Plan anual de achiziții publice centralizate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/PaapCentralization'),
  },
  {
    path: '/:navigation/paap/:id/centralizare/:centralizationId',
    name: 'Plan anual de achiziții publice ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Paap'),
  },

  {
    path: '/:navigation/paap/:id/centralizare/history/:version',
    name: 'Plan anual de achiziții publice centralizate, istoric',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/PaapCentralization'),
  },
  
  {
    path: '/:navigation/paap/:id/centralizare/:centralizationId/history/:version',
    name: 'Plan anual de achiziții publice  ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Paap'),
  },
  {
    path: '/public/paap/:paap_token',
    name: 'Plan anual de achiziții publice (PAAP)',
    meta: { layout: 'mainLayout' },
    component: () => import('@/views/PublicPaap'),
  },

  {
    path: '/:navigation/referate',
    name: 'Referate necesitate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/referate/:id',
    name: 'Referate necesitate ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/referate/:id/:version',
    name: 'Versiune referat necesitate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Referate'),
  },
  {
    path: '/:navigation/surse_finantare',
    name: 'Surse finantare',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ProjectFinancingSources'),
  },
  {
    path: '/:navigation/tipuri_contracte',
    name: 'Tipuri Contracte',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ContractTypes'),
  },
  {
    path: '/:navigation/tip_proceduri',
    name: 'Tipuri de proceduri',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ProcedureType'),
  },
  {
    path: '/:navigation/unitati_masura',
    name: 'Unități de măsură',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/UnitatiMasura'),
  },
  {
    path: '/:navigation/categorii_cpv',
    name: 'Categorii CPV',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Categorii_cpv'),
  },
  {
    path: '/:navigation/catalog',
    name: 'Catalog',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Catalog'),
  },
  {
    path: '/:navigation/catalog/:id/:version',
    name: 'Catalog ',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Catalog'),
  },
/*   {
    path: '/:navigation/obiective_referate',
    name: 'Obiective referate',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ReferateObiective'),
  }, */
  {
    path: '/:navigation/obietive-institutie',
    name: 'Obiective institutie',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/InstitutionObjectives'),
  },

  {
    path: '/:navigation/obietive-anuale',
    name: 'Obiective anuale',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/AnnualInstitutionObjectives'),
  },
  {
    path: '/:navigation/categorii_achizitii',
    name: 'Categorii achiziții',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Achizitii'),
  },
  {
    path: '/:navigation/clasificatie_bugetare',
    name: 'Clasificație bugetare',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Bugetare'),
  },
  {
    path: '/:navigation/nevoi',
    name: 'Propuneri de nevoi',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Needs'),
  },
  {
    path: '/:navigation/nevoi/avizare-aprobare',
    name: 'Avizare & Aprobare',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/Needs'),
  },
  {
    path: '/:navigation/nevoi/pdf',
    name: 'Necesități - Previzualizare PDF',
    meta: { layout: 'emptyLayout', needPdf: true, needAuth: true },
    component: () => import('@/components/PdfPreview'),
  },
  {
    path: '/:navigation/furnizori',
    name: 'Furnizori',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/InstitutionProvider'),
  },

  {
    path: '/:navigation/raport-centralizare',
    name: 'Raport achiziții',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/PaapCentralizationReports'),
  },
  {
    path: '/:navigation/raport-produse',
    name: 'Raport produse',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/ProductsReports'),
  },
  {
    path: '/:navigation/raport-buget',
    name: 'Raport buget',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/BudgetReports'),
  },
  {
    path: '/:navigation/structuri-organizatorice',
    name: 'Tipuri de structuri organizatorice',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/OrganizationalStructureTypeView'),
  },
  {
    path: '/:navigation/tipuri-de-institutii',
    name: 'Tipuri de instituții',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/InstitutionTypeView'),
  },
  {
    path: '/:navigation/ordonatori-credite',
    name: 'Tipuri de ordonatori de credite',
    meta: { layout: 'mainLayout', needAuth: true },
    component: () => import('@/views/CreditOrdererTypeView'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'authLayout', needAuth: false },
    component: () => import('@/views/Login'),
  },
  {
    path: '/register/fizic',
    name: 'signin1',
    meta: { layout: 'authLayout', needAuth: false },
    component: () => import('@/views/Signin1'),
  },
  {
    path: '/register/juridic',
    name: 'signin2',
    meta: { layout: 'authLayout', needAuth: false },
    component: () => import('@/views/Signin2'),
  },
  {
    path: '/forgot/password',
    name: 'forgot',
    meta: { layout: 'authLayout', needAuth: false },
    component: () => import('@/views/ForgotPassword'),
  },
  {
    path: '/password/reset/:token/:email',
    name: 'PassReset',
    meta: { layout: 'authLayout' },
    component: () => import('@/views/ResetPassword'),
  },
  {
    path: '/password/set/:token/:email',
    name: 'PassReset ',
    meta: { layout: 'authLayout', firstReset: true },
    component: () => import('@/views/ResetPassword'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { layout: 'authLayout' },
    component: () => import('@/views/Logout'),
  },
  {
    path: '*',
    meta: { layout: 'NotFound' },
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function checkRole(to) {
  if (!to || !to.path) return false
  const x = String(to.path)
    .trim()
    .toLowerCase()

  const permMap = {
    '/proiecte': 'projects.get',
    '/surse_finantare': 'financing.get',
    '/tipuri_contracte': 'contract.get',
    '/nevoi': 'needs.get',
    '/valute': 'currency.get',
    '/registrul_pragurilor': 'registru_praguri.get',
    '/referate': 'referat-necesitate.get',
/*     '/achizitii_directe': 'referat-necesitate.get',
    '/proceduri_simplificate': 'referat-necesitate.get',
    '/licitatii_publice': 'referat-necesitate.get', */
    '/paap': 'paap.get',
    '/referate/preview': 'referat-necesitate.generate_pdf',
    '/catalog': 'products.get',
    '/categorii_cpv': 'cpv-categories.get',
    '/unitati_masura': 'measurement-units.get',
    '/tip_proceduri': 'procedure-types.get',
    '/departamente': 'departments.get',
    '/institutii': 'institutions.get',
    '/institutie': 'institution.get',
    '/utilizatori': 'usersManagement.get',
    '/obiective_referate': 'referate_obiective.get',
    '/clasificatie_bugetare': 'bugetare.get',
    '/categorii_achizitii': 'achizitii.get',
  }
  const findPerm = Object.entries(permMap).find((e) => to.path.startsWith(e[0]))
  if (Array.isArray(findPerm) && !window.$permReader(findPerm[1])) {
    return true
  }
  return false
}

router.beforeEach((to, from, next) => {
  const token = window.$jwt.decode(window.$jwt.getToken())
  const role = window.$getRoleAll()
  
  if (to.name === 'logout') {
    next()
    return
  }

  if (to.meta.needAuth && !token) {
    next({ name: 'login' })
  } else if (token ? parseInt(token.exp) - Date.now() / 1000 < 0 : false) {
    next({ name: 'logout' })

    if (window.$sesionExpired) {
      window.$sesionExpired()
    }
  } else if (
    (to.meta.needAuth === false && token) ||
    checkRole(to) ||
    (to.path == '/nevoi/avizare-aprobare' && role.role == 'superadmin')
  ) {
    const app = document.body
    if (app) {
      app.classList?.remove('__Loading')
    }
    next({ name: 'home' })
  } else next()
})

export default router
